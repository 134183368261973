"use client";

import ErrorPage from "@/components/layout/ErrorPage";
import Image from "next/image";

export default function NotFound() {
  return (
    <ErrorPage
      title="Page not found"
      message="The page you are looking for does not exist."
      icon={<Image src="/images/bad-link-circle.svg" alt="404" width={200} height={200} />}
    />
  );
}
