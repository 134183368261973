import ProgressSteps from "@/components/ui/ProgressSteps";
import React from "react";

interface Props {
  profilesVisited: number;
  maxProfiles: number;
  heightClass: string;
}

export default function VisitProgressBar({ profilesVisited, maxProfiles, heightClass }: Props) {
  const progressBarSteps = 6;
  const cappedProfilesVisited = Math.min(profilesVisited, maxProfiles);
  const visitsPerBar = maxProfiles / progressBarSteps;
  const barsFilled = Math.floor(cappedProfilesVisited / visitsPerBar);
  const currentStepPercentage =
    ((cappedProfilesVisited - barsFilled * visitsPerBar) / visitsPerBar) * 100;

  return (
    <div className="flex flex-row w-full">
      <ProgressSteps
        stepsDone={barsFilled}
        stepsLeft={progressBarSteps - barsFilled}
        currentStepPercentage={
          cappedProfilesVisited != maxProfiles ? currentStepPercentage : undefined
        }
        barClassName={heightClass}
      />
      <div className="text-brand-600 shrink-0 ml-2 font-semibold text-sm">
        {cappedProfilesVisited}/{maxProfiles}
      </div>
    </div>
  );
}
