"use client";
import Navbar from "@/components/navbar/Navbar";
import Button from "@/components/ui/Button";
import { useUI } from "@/hooks/useUIStore";
import { ServerProps } from "@/shared/serverProps";
import { uiStore } from "@/stores/uiStore";
import { useStore } from "@nanostores/react";
import Link from "next/dist/client/link";
import React from "react";

/**
 * This ErrorPage component uses the Navbar, so when in use you must call useUI() on the page as well as loadWorkspaceData() in the getServerSideProps.
 */
const ErrorPage = ({
  title,
  message,
  icon,
  props,
}: {
  title: string;
  message: string;
  icon: React.JSX.Element;
  props?: ServerProps;
}) => {
  useUI(props);
  const user = useStore(uiStore.user);

  return (
    <div className="flex-1 max-h-full flex flex-col overflow-hidden bg-gray-100">
      <Navbar />
      <div className="flex flex-col items-center justify-center h-screen w-full gap-6">
        {icon}
        <div className="flex flex-col items-center justify-center gap-2">
          <h1 className="text-2xl font-medium">{title}</h1>
          <p className="text-lg text-gray-500">{message}</p>
        </div>
        <Link href={user ? "/dashboard" : "/login"}>
          <Button className="px-8 py-1">Back Home</Button>
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
