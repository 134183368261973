import Button, { ButtonVariant } from "@/components/ui/Button";
import PopoverMenu from "@/components/ui/PopoverMenu";
import ProgressCircle from "@/components/ui/ProgressCircle";
import VisitProgressBar from "@/components/visitLimit/VisitProgressBar";
import { pluralize } from "@/lib/stringUtils";
import eventTracker from "@/lib/trackers/eventTracker";
import { uiStore } from "@/stores/uiStore";
import { useStore } from "@nanostores/react";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";

export default function VisitLimitIcon({ className }: { className?: string }) {
  const entityVisitCount = useStore(uiStore.entityVisitCount);
  const profileVisits = (entityVisitCount?.visitedIds as string[])?.length;
  const maxVisits = useStore(uiStore.maxEntityVisits);
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (!entityVisitCount || profileVisits === undefined) return null;

  const cappedProfileVisits = Math.min(profileVisits, maxVisits);

  return (
    <div
      data-tooltip-id="tooltip"
      data-tooltip-content={
        popoverOpen ? undefined : (
          `${pluralize(cappedProfileVisits, "profile")} viewed this month (of ${maxVisits})`
        )
      }
      className={twJoin("mr-2", className)}
    >
      <PopoverMenu
        buttonClass={twJoin("", "w-full")}
        buttonLabel={
          <ProgressCircle
            progress={Math.min((cappedProfileVisits || 0) / maxVisits, 1)}
            size={32}
            color={"grey"}
          />
        }
        onOpen={() => {
          setPopoverOpen(true);
          eventTracker.capture("open-visit-limit-popover", {
            count: `${cappedProfileVisits} / ${maxVisits}`,
          });
        }}
        onClose={() => {
          setPopoverOpen(false);
          eventTracker.capture("close-visit-limit-popover");
        }}
        floatingOptions={{ placement: "bottom" }}
      >
        <div className="flex flex-col gap-3 p-5 items-center min-w-80 cursor-default">
          <div className="w-full font-semibold text-md text-left">Profile Views:</div>

          <VisitProgressBar
            profilesVisited={cappedProfileVisits}
            maxProfiles={maxVisits}
            heightClass={"h-3"}
          />
          <div className="w-full text-sm text-gray-500 text-left mt-1">
            {cappedProfileVisits} out of {maxVisits} Monthly Profile Views Used
          </div>
          <hr className="mt-4 mb-1 w-full" />
          <div className="hidden">
            <Button className="font-semibold">Connect LinkedIn to View More Profiles</Button>
          </div>
          <div className="text-gray-500 text-center">
            Upgrade (coming soon): $30/month <br /> (limit not enforced during beta)
          </div>
        </div>
      </PopoverMenu>
    </div>
  );
}
