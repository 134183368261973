import { SidebarVariant, useSidebar } from "@/components/providers/SidebarProvider";
import SearchBox from "@/components/search/SearchBox";
import SettingsButton from "@/components/settings/SettingsButton";
import Avatar from "@/components/ui/Avatar";
import { LoggedOutButtons } from "@/components/ui/LoggedOutButtons";
import Logo from "@/components/ui/Logo";
import VisitLimitIcon from "@/components/visitLimit/VisitLimitIcon";
import { uiStore } from "@/stores/uiStore";
import {
  COLOR_ACTIVABLE,
  COLOR_ACTIVE,
  COLOR_INACTIVE,
  defaultPageWidthClass,
} from "@/styles/constants";
import { UserWithMeta } from "@/types";
import { Bars3Icon, ClockIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { HomeIcon, QueueListIcon } from "@heroicons/react/24/solid";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

export default function Navbar({
  noSearch,
  titleButtons,
}: {
  noSearch?: boolean;
  titleButtons?: React.ReactNode;
}) {
  const user = useStore(uiStore.user);
  const { openSidebar } = useSidebar();

  const openMobileNav = () => {
    openSidebar(SidebarVariant.MobileNav, {});
  };

  return (
    <>
      {/* Desktop Navigation - hidden on small screens */}
      <nav className="hidden sm:flex py-2 px-6 h-[56px] gap-4 justify-between shadow-card z-10 bg-white">
        <div className={twJoin(defaultPageWidthClass, "flex gap-x-6 items-center justify-between")}>
          <div className="flex items-center">
            <Link href={user ? "/dashboard" : "/"}>
              <Logo className="max-w-8" />
            </Link>
          </div>

          {user && !noSearch && (
            <div className="flex-1 mx-4">
              <SearchBox
                submitButton
                className="max-w-72 lg:max-w-96"
                magnifyClassName="left-4"
                placeholder="Search for a person or company"
                showAdvancedSearch
              />
            </div>
          )}

          {user && (
            <div className="flex space-x-1 text-gray-500">
              <NavLink href="/dashboard" tooltip="Your dashboard">
                <HomeIcon className="w-4 h-4 mr-2 hidden md:inline" /> Home
              </NavLink>
              <NavLink href="/recents" tooltip="Recently viewed people and companies">
                <ClockIcon className="w-4 h-4 mr-2 hidden md:inline" /> Recents
              </NavLink>
              <NavLink href="/lists" tooltip="Create and view lists">
                <QueueListIcon className="w-4 h-4 mr-2 hidden md:inline" /> Lists
              </NavLink>
            </div>
          )}

          <div className="flex gap-x-2 items-center">
            {titleButtons}
            <VisitLimitIcon />

            {user ?
              <SettingsButton />
            : <LoggedOutButtons />}
          </div>
        </div>
      </nav>

      {/* Mobile Navigation - visible only on small screens */}
      <MobileNavbar user={user} noSearch={noSearch} openMobileNav={openMobileNav} />
    </>
  );
}

// Mobile navigation component
function MobileNavbar({
  user,
  noSearch,
  openMobileNav,
}: {
  user: UserWithMeta | null;
  noSearch?: boolean;
  openMobileNav: () => void;
}) {
  const [searchExpanded, setSearchExpanded] = useState(false);
  const pathname = usePathname();

  const toggleSearch = () => {
    setSearchExpanded(!searchExpanded);
  };

  return (
    <nav className="sm:hidden py-2 px-4 h-[56px] flex items-center justify-between shadow-card z-10 bg-white">
      {searchExpanded ?
        // Expanded search view
        <div className="flex-1 flex items-center">
          <button className="p-2 text-gray-400" onClick={toggleSearch}>
            <XMarkIcon className="h-5 w-5" />
          </button>
          <div className="flex-1">
            <SearchBox
              className="w-full"
              placeholder="Search for a person or company"
              showAdvancedSearch
              autoFocus={true}
            />
          </div>
        </div>
      : <>
          {/* Logo */}
          <div className="flex items-center">
            <Link href={user ? "/dashboard" : "/"}>
              <Logo className="max-w-8" />
            </Link>
          </div>

          {/* Right side icons */}
          <div className="flex items-center gap-4">
            {/* Search icon */}
            {user && !noSearch && (
              <button
                className="p-1 rounded-full text-gray-500 hover:bg-gray-100"
                onClick={toggleSearch}
                aria-label="Search"
              >
                <MagnifyingGlassIcon className="w-8 h-8" />
              </button>
            )}

            {/* Recents icon */}
            {user && (
              <Link
                href="/recents"
                className={twJoin(
                  pathname === "/recents" ? "text-indigo-600" : "text-gray-500",
                  "p-1 rounded-full hover:bg-gray-100",
                )}
                aria-label="Recents"
              >
                <ClockIcon className="w-8 h-8" />
              </Link>
            )}

            {/* User avatar */}
            {user && <Avatar user={user} className="w-8 h-8 rounded-full" />}

            {/* Mobile menu button */}
            {user ?
              <button
                type="button"
                className="rounded-md p-1 text-gray-500 hover:bg-gray-100"
                onClick={openMobileNav}
                aria-label="Open menu"
              >
                <Bars3Icon className="w-8 h-8" aria-hidden="true" />
              </button>
            : <LoggedOutButtons />}
          </div>
        </>
      }
    </nav>
  );
}

export const NavLink = ({
  href,
  onClick,
  children,
  tooltip,
}: {
  href: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
  tooltip?: string;
}) => {
  const [effect, setEffect] = useState(false);
  const pathname = usePathname();
  const baseNavClass = "p-2 rounded-md";

  return (
    <Link
      data-tooltip-id="tooltip"
      data-tooltip-content={tooltip}
      className={twJoin(
        pathname === href ? COLOR_ACTIVE : COLOR_INACTIVE,
        COLOR_ACTIVABLE,
        "flex items-center font-medium",
        baseNavClass,
        effect && "animate-singlePulse",
      )}
      onClick={(e) => {
        setEffect(true);
        onClick?.(e);
      }}
      onAnimationEnd={() => setEffect(false)}
      href={href}
    >
      {children}
    </Link>
  );
};
